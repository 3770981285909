export const STORES_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const TIME_UNTIL_SUCCESS_TOAST_DISAPPEARS = 5000;
export const TIME_UNTIL_ERROR_TOAST_DISAPPEARS = 10000;

export enum UpmFlowStatus {
  INIT = 'init',
  PENDING = 'pending',
}

export enum PayNowFlowStatus {
  INIT = 'init',
  PENDING = 'pending',
}
